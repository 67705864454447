@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  font-family: Arial, sans-serif;
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  stroke: #000;
  stroke-width: 1px;
  fill: #fff;
  background-color: #1a1a1a;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark gradient background */
.gradient-bg {
  /* background-image: linear-gradient(45deg, #1a1a1a, #3b3b3b); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 20px;
  color: #fff;
}

.ant-input-number-input{
  background-color: #1a1a1a;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffd700;
  color: #fff;
}

/* Gold button and accents */
.ant-btn-primary {
  background-color: #ffd90000;
  border-color: #ffd700;
  color: #fff;
  border-radius: 5px;
  border-width: 2px;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #2d2c2a !important;
  border-color: #ffcc00 !important;
  color: #666666 !important;
  border-width: 1px !important;
}

.form {
  align-content: 'center'; 
  justify-content: 'center';
  margin: '0 auto';
}

.form-item .ant-form-item-label > label {
  color: #fff;
}

.ant-layout {
  background: #1a1a1a;
}


.title {
	font-family: Catamaran !important;
	font-size: 3rem !important;
	align-self: center !important;
	color: white !important;
	display: flex !important;
	align-items: center !important;
	vertical-align: middle !important;
}

.logo {
	width: 64px;
	height: 64px;
	font-family: Catamaran;
	font-size: 2rem;
	align-self: center;
	color: white;
	display: flex;
	align-items: center;
	vertical-align: middle;
}

.nav-container {
	font-size: 2rem !important;
	font-weight: bold !important;
	align-self: center !important;
	color: white !important;
	display: flex !important;
	align-items: center !important;
	vertical-align: middle !important;
	margin-top: 15px !important;
}

.ant-table-thead > tr > th {
  background-color: #1a1a1a !important;
  color: #fff !important;
  border-color: #1a1a1a !important;
  border-width: 0px !important;
}

/* Dark theme for table rows */
.ant-table-tbody > tr > td {
  background-color: #1a1a1a;
  color: #fff;
}

.ant-table-tbody > tr:hover {
  background-color: #2d2c2a !important;
  color: #fff !important;
}


.ant-table .ant-table-tbody > tr:hover > td {
  background-color: #2d2c2a !important;
  color: #fff !important;
}

/* Change the navigation arrows color */
.ant-table .ant-pagination-item-link {
  color: #eceae1;
}

/* Change the pagination text color */
.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-pagination-prev a,
.ant-pagination .ant-pagination-next a,
.ant-pagination .ant-pagination-jump-prev a,
.ant-pagination .ant-pagination-jump-next a {
  color: #ffffff;
}

/* Change the pagination button hover and focus background color */
.ant-pagination .ant-pagination-item:hover a,
.ant-pagination .ant-pagination-item:focus a,
.ant-pagination .ant-pagination-prev:hover a,
.ant-pagination .ant-pagination-prev:focus a,
.ant-pagination .ant-pagination-next:hover a,
.ant-pagination .ant-pagination-next:focus a,
.ant-pagination .ant-pagination-jump-prev:hover a,
.ant-pagination .ant-pagination-jump-prev:focus a,
.ant-pagination .ant-pagination-jump-next:hover a,
.ant-pagination .ant-pagination-jump-next:focus a {
  background-color: #3a3a3a;
}

/* Change the pagination button active background color */

.ant-pagination .ant-pagination-item-active {
  background-color: #1a1a1a;
  border-color:  #ffcc00;
  color: #d7d7d7 !important;
}

.ant-pagination .ant-pagination-item-link {
  color: #ffcc00 !important;
}

.ant-form .ant-form-item-control-input {
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffd700;
  color: #fff;
}

.ant-form .ant-form-item-control-input input {
  background-color: #1a1a1a;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffd700;
  color: #fff;
}

.ant-form .ant-form-item-control-input input:hover {
  background-color: #2d2c2a;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffd700;
  color: #fff;
}

.table {
  background-color: #1a1a1a;
  border-radius: 5px;
  border-width: 2px;
  border-color: #ffd700;
  color: #fff;
}

.password-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.password-input {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid white !important;
}

.submit-button {
  display: block;
  width: 100%;
  color: #000;
  background-color: #ffd700;  /* Modify this to change button color */
}
